import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_inner_image_zoom = _resolveComponent("inner-image-zoom");

  var _component_ModalLayout = _resolveComponent("ModalLayout");

  return _openBlock(), _createBlock(_component_ModalLayout, {
    "close-button": true,
    "max-width": "800px",
    title: "Zoom",
    "is-margin-auto": true
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_inner_image_zoom, {
        src: _ctx.imgres,
        fullscreenOnMobile: true
      }, null, 8, ["src"])];
    }),
    _: 1
  });
}