import { defineComponent } from "vue";
export default defineComponent({
  name: "navbar",
  props: {
    id: {
      type: String
    }
  },
  setup: function setup(props) {
    var logoSrc = "images/".concat(process.env.VUE_APP_LOGO);
    return {
      logoSrc: logoSrc
    };
  }
});