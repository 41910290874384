import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-bff22b04"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  key: 0,
  class: "products-nuancier overflow-auto"
};
var _hoisted_2 = {
  key: 1,
  class: "overflow-auto"
};
var _hoisted_3 = {
  class: "colors-products ps-0"
};
var _hoisted_4 = ["onClick"];
var _hoisted_5 = ["alt"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_InfoNuancier = _resolveComponent("InfoNuancier");

  var _component_ModalLayout = _resolveComponent("ModalLayout");

  return _openBlock(), _createBlock(_component_ModalLayout, {
    "close-button": true,
    "max-width": "1000px",
    "display-arrow": _ctx.isColorPage,
    title: _ctx.isColorPage ? "S\xE9lectionnez la couleur du mur" : "S\xE9lectionnez type de nuancier",
    "return-function": function returnFunction() {
      _ctx.isColorPage = false;
    }
  }, {
    default: _withCtx(function () {
      return [!_ctx.isColorPage ? (_openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.infoNuancier, function (item) {
        return _openBlock(), _createBlock(_component_InfoNuancier, {
          key: item.id,
          "image-src": item.src,
          "image-alt": item.alt,
          title: item.title,
          filter: function filter() {
            return _ctx.handleFilter(item.id);
          },
          condition: _ctx.activeNuancier === item.id
        }, null, 8, ["image-src", "image-alt", "title", "filter", "condition"]);
      }), 128))])) : _createCommentVNode("", true), _ctx.isColorPage ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("ul", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nuancierColor, function (item, index) {
        var _ctx$product, _ctx$product2;

        return _openBlock(), _createElementBlock("li", {
          key: index,
          style: {
            "cursor": "pointer"
          },
          class: "list-unstyled mb-10 wall-images",
          onClick: function onClick($event) {
            return _ctx.selectWall(item);
          }
        }, [_createElementVNode("div", {
          class: _normalizeClass(["color-wall rounded w-125px h-75px w-sm-150px h-sm-70px", [item.réference === ((_ctx$product = _ctx.product) === null || _ctx$product === void 0 ? void 0 : _ctx$product.réference) && 'active-img-wall']]),
          style: _normalizeStyle({
            backgroundColor: "rgb(".concat(item.rgb, ")")
          }),
          alt: item.réference,
          loading: "lazy"
        }, null, 14, _hoisted_5), _createElementVNode("div", {
          class: _normalizeClass([[item.réference === ((_ctx$product2 = _ctx.product) === null || _ctx$product2 === void 0 ? void 0 : _ctx$product2.réference) && 'active-title-wall'], "title-wall w-125px w-sm-150px h-25px h-sm-30px rounded bg-light mt-2 text-center pt-2 fs-sm-6 fs-8"])
        }, _toDisplayString(item.réference), 3)], 8, _hoisted_4);
      }), 128))])])) : _createCommentVNode("", true)];
    }),
    _: 1
  }, 8, ["display-arrow", "title", "return-function"]);
}