import { defineComponent } from "vue";
export default defineComponent({
  props: {
    condition: {
      type: Boolean
    },
    title: {
      type: String
    },
    info: {
      type: String
    }
  },
  components: {},
  setup: function setup() {
    return {};
  }
});