import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "d-flex flex-column"
};
var _hoisted_2 = {
  class: "fw-bold mb-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _ctx.condition ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.title), 1), _createElementVNode("span", null, _toDisplayString(_ctx.info), 1)])) : _createCommentVNode("", true);
}