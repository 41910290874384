import { buildTreatedImageUrl } from "@/core/helpers/config";
import store from "@/store";
import { computed, defineComponent, ref } from "vue";
import { ElNotification } from "element-plus";
import ZoomSVG from "../../../assets/svg/ZoomSVG.vue";

var __default__ = defineComponent({
  name: "zoom-modal",
  components: {},
  setup: function setup() {
    var color = process.env.VUE_APP_COLOR;
    var networks = ref([{
      network: "email",
      name: "Email",
      icon: "svg/icons/btn_email.svg",
      style: "fill: ".concat(color)
    }, {
      network: "facebook",
      name: "Facebook",
      icon: "svg/icons/btn_facebook.svg",
      style: "fill: ".concat(color)
    }]);
    var colorValue = computed(function () {
      return store.getters.getwallColor;
    });
    var floorPattern = computed(function () {
      return store.getters.getFloorPattern;
    });
    var imgName = computed(function () {
      return store.getters.getImgName;
    });
    var sharingURL = computed(function () {
      var u = buildTreatedImageUrl(imgName.value, {
        color: colorValue.value,
        floor: floorPattern.value
      });
      return u;
    });
    return {
      networks: networks,
      sharingURL: sharingURL,
      ElNotification: ElNotification,
      color: color,
      ZoomSVG: ZoomSVG
    };
  }
});

import { useCssVars as _useCssVars } from 'vue';

var __injectCSSVars__ = function __injectCSSVars__() {
  _useCssVars(function (_ctx) {
    return {
      "22e36095": _ctx.color
    };
  });
};

var __setup__ = __default__.setup;
__default__.setup = __setup__ ? function (props, ctx) {
  __injectCSSVars__();

  return __setup__(props, ctx);
} : __injectCSSVars__;
export default __default__;