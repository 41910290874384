import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import { defineComponent, ref } from "vue";
import ModalLayout from "@/components/modals/ModalLayout.vue";
import colorWall from "@/core/data/colorWall";
import InfoNuancier from "../parts/InfoNuancier.vue";
import { infoNuancier } from "@/core/data/infoNuancier";
import { setWallColor } from "@/core/helpers/config";
export default defineComponent({
  name: "select-wall",
  components: {
    ModalLayout: ModalLayout,
    InfoNuancier: InfoNuancier
  },

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  setup: function setup() {
    var color = ref(colorWall);
    var activeNuancier = ref();
    var isColorPage = ref(false);
    var product = ref();

    var selectWall = function selectWall(wall) {
      product.value = wall;
      setWallColor(wall.rgb);
    };

    var nuancierColor = ref(color.value);

    var sortNuancierColor = function sortNuancierColor(myArr) {
      return myArr.sort(function (a, b) {
        return a.réference.substring(a.réference.indexOf("n°") + 2, a.réference.length) - b.réference.substring(b.réference.indexOf("n°") + 2, b.réference.length);
      });
    };

    var handleFilter = function handleFilter(idNuancier) {
      nuancierColor.value = color.value.filter(function (type) {
        return type.idNuancier === idNuancier;
      });
      activeNuancier.value = idNuancier;
      isColorPage.value = true; // order colors by n°

      nuancierColor.value = sortNuancierColor(nuancierColor.value);
    };

    return {
      infoNuancier: infoNuancier,
      handleFilter: handleFilter,
      nuancierColor: nuancierColor,
      product: product,
      selectWall: selectWall,
      activeNuancier: activeNuancier,
      isColorPage: isColorPage
    };
  }
});