import { buildTreatedImageUrl } from "@/core/helpers/config";
import store from "@/store";
import { computed, defineComponent } from "vue";
import ShareModal from "./modals/modal-component/ShareModal.vue";
import UploadSVG from "../assets/svg/UploadSVG.vue";
import FloorSVG from "../assets/svg/FloorSVG.vue";
import WallSVG from "../assets/svg/WallSVG.vue";
import CompareSVG from "../assets/svg/CompareSVG.vue";
import ShareSVG from "../assets/svg/ShareSVG.vue";
import ZoomSVG from "../assets/svg/ZoomSVG.vue";
import PrintSVG from "../assets/svg/PrintSVG.vue";
import DownloadSVG from "../assets/svg/DownloadSVG.vue";
export default defineComponent({
  name: "menu-mobile",
  props: {
    handleCompare: {
      type: Function,
      required: true
    },
    openModalFloor: {
      type: Function,
      required: true
    },
    openModalWall: {
      type: Function,
      required: true
    },
    openModalEspace: {
      type: Function,
      required: true
    },
    handleShare: {
      type: Function
    },
    printImage: {
      type: Function
    },
    handleZoom: {
      type: Function
    }
  },
  components: {
    ShareModal: ShareModal,
    UploadSVG: UploadSVG,
    FloorSVG: FloorSVG,
    WallSVG: WallSVG,
    CompareSVG: CompareSVG,
    ShareSVG: ShareSVG,
    ZoomSVG: ZoomSVG,
    PrintSVG: PrintSVG,
    DownloadSVG: DownloadSVG
  },
  setup: function setup() {
    var myColor = process.env.VUE_APP_COLOR;
    var colorValue = computed(function () {
      return store.getters.getwallColor;
    });
    var floorPattern = computed(function () {
      return store.getters.getFloorPattern;
    });
    var imgName = computed(function () {
      return store.getters.getImgName;
    });
    var sharingURL = computed(function () {
      var u = buildTreatedImageUrl(imgName.value, {
        color: colorValue.value,
        floor: floorPattern.value
      });
      return u;
    });
    return {
      sharingURL: sharingURL,
      myColor: myColor
    };
  }
});