interface IColorWall {
  réference: string;
  nuancier: string;
  marque?: string;
  rgb?: string;
  idNuancier: number;
}

const colorWall: Array<IColorWall> = [
  {
    réference: "Indigotier n°200",
    nuancier: "Les Pétillantes",
    rgb: "59,89,142",
    marque: "Sign'Nature",
    idNuancier: 1,
  },
  {
    réference: "Eucalyptus n°201",
    nuancier: "Les Pétillantes",
    rgb: "0,147,117",
    marque: "Sign'Nature",
    idNuancier: 1,
  },
  {
    réference: "Hortensia n°202",
    nuancier: "Les Pétillantes",
    rgb: "77,194,190",
    marque: "Sign'Nature",
    idNuancier: 1,
  },
  {
    réference: "Verveine n°203",
    nuancier: "Les Pétillantes",
    rgb: "111,189,97",
    marque: "Sign'Nature",
    idNuancier: 1,
  },
  {
    réference: "Matcha n°204",
    nuancier: "Les Pétillantes",
    rgb: "157,188,59",
    marque: "Sign'Nature",
    idNuancier: 1,
  },
  {
    réference: "Algue bleue n°205",
    nuancier: "Les Pétillantes",
    rgb: "0,111,107",
    marque: "Sign'Nature",
    idNuancier: 1,
  },
  {
    réference: "Gentiane n°206",
    nuancier: "Les Pétillantes",
    rgb: "130,85,142",
    marque: "Sign'Nature",
    idNuancier: 1,
  },
  {
    réference: "Bourrache n°207",
    nuancier: "Les Pétillantes",
    rgb: "111,117,188",
    marque: "Sign'Nature",
    idNuancier: 1,
  },
  {
    réference: "Iris n°208",
    nuancier: "Les Pétillantes",
    rgb: "83,87,152",
    marque: "Sign'Nature",
    idNuancier: 1,
  },
  {
    réference: "Jamalac n°209",
    nuancier: "Les Pétillantes",
    rgb: "184,81,125",
    marque: "Sign'Nature",
    idNuancier: 1,
  },
  {
    réference: "Lilas des Indes n°210",
    nuancier: "Les Pétillantes",
    rgb: "185,85,121",
    marque: "Sign'Nature",
    idNuancier: 1,
  },
  {
    réference: "Poivre Rose n°211",
    nuancier: "Les Pétillantes",
    rgb: "160,69,83",
    marque: "Sign'Nature",
    idNuancier: 1,
  },
  {
    réference: "Bigarade n°212",
    nuancier: "Les Pétillantes",
    rgb: "213,89,59",
    marque: "Sign'Nature",
    idNuancier: 1,
  },
  {
    réference: "Safran n°213",
    nuancier: "Les Pétillantes",
    rgb: "191,63,59",
    marque: "Sign'Nature",
    idNuancier: 1,
  },
  {
    réference: "Espelette n°214",
    nuancier: "Les Pétillantes",
    rgb: "153,67,61",
    marque: "Sign'Nature",
    idNuancier: 1,
  },
  {
    réference: "Curcuma n°215",
    nuancier: "Les Pétillantes",
    rgb: "222,135,61",
    marque: "Sign'Nature",
    idNuancier: 1,
  },
  {
    réference: "Curry n°216",
    nuancier: "Les Pétillantes",
    rgb: "244,189,0",
    marque: "Sign'Nature",
    idNuancier: 1,
  },
  {
    réference: "Carambole n°217",
    nuancier: "Les Pétillantes",
    rgb: "253,202,0",
    marque: "Sign'Nature",
    idNuancier: 1,
  },
  {
    réference: "Blanc Narcisse n°218",
    nuancier: "les pastels",
    rgb: "237,235,229",
    marque: "Sign'Nature",
    idNuancier: 2,
  },
  {
    réference: "Perle fine n°219",
    nuancier: "les pastels",
    rgb: "233,228,223",
    marque: "Sign'Nature",
    idNuancier: 2,
  },
  {
    réference: "Cocoon n°220",
    nuancier: "les pastels",
    rgb: "222,214,200",
    marque: "Sign'Nature",
    idNuancier: 2,
  },
  {
    réference: "Aigue-Marine n°221",
    nuancier: "les pastels",
    rgb: "214,218,227",
    marque: "Sign'Nature",
    idNuancier: 2,
  },
  {
    réference: "Jeune Pousse n°222",
    nuancier: "les pastels",
    rgb: "231,234,218",
    marque: "Sign'Nature",
    idNuancier: 2,
  },
  {
    réference: "Sable Chaud n°223",
    nuancier: "les pastels",
    rgb: "240,227,206",
    marque: "Sign'Nature",
    idNuancier: 2,
  },
  {
    réference: "Bois de Rose n°224",
    nuancier: "les pastels",
    rgb: "237,223,223",
    marque: "Sign'Nature",
    idNuancier: 2,
  },
  {
    réference: "Porcelaine n°225",
    nuancier: "les pastels",
    rgb: "231,226,213",
    marque: "Sign'Nature",
    idNuancier: 2,
  },
  {
    réference: "Gris Cachemire n°226",
    nuancier: "les pastels",
    rgb: "220,217,218",
    marque: "Sign'Nature",
    idNuancier: 2,
  },
  {
    réference: "Lumière Douce n°227",
    nuancier: "les pastels",
    rgb: "240,231,213",
    marque: "Sign'Nature",
    idNuancier: 2,
  },
  {
    réference: "Lagon n°228",
    nuancier: "les pastels",
    rgb: "214,218,227",
    marque: "Sign'Nature",
    idNuancier: 2,
  },
  {
    réference: "Thé Vert n°229",
    nuancier: "les pastels",
    rgb: "225,234,228",
    marque: "Sign'Nature",
    idNuancier: 2,
  },
  {
    réference: "Laine Vierge n°230",
    nuancier: "les pastels",
    rgb: "237,235,229",
    marque: "Sign'Nature",
    idNuancier: 2,
  },
  {
    réference: "Lin Douceur n°231",
    nuancier: "les pastels",
    rgb: "228,220,204",
    marque: "Sign'Nature",
    idNuancier: 2,
  },
  {
    réference: "Glacier n°232",
    nuancier: "les pastels",
    rgb: "209,216,226",
    marque: "Sign'Nature",
    idNuancier: 2,
  },
  {
    réference: "Brume n°233",
    nuancier: "les pastels",
    rgb: "221,221,221",
    marque: "Sign'Nature",
    idNuancier: 2,
  },
  {
    réference: "Crème Délice n°234",
    nuancier: "les pastels",
    rgb: "237,228,218",
    marque: "Sign'Nature",
    idNuancier: 2,
  },
  {
    réference: "Orchidée n°235",
    nuancier: "les pastels",
    rgb: "219,213,221",
    marque: "Sign'Nature",
    idNuancier: 2,
  },
  {
    réference: "Petit Gris n°236",
    nuancier: "les pastels",
    rgb: "222,221,217",
    marque: "Sign'Nature",
    idNuancier: 2,
  },
  {
    réference: "Dune n°237",
    nuancier: "les pastels",
    rgb: "230,223,208",
    marque: "Sign'Nature",
    idNuancier: 2,
  },
  {
    réference: "Soie Sauvage n°238",
    nuancier: "les pastels",
    rgb: "224,220,211",
    marque: "Sign'Nature",
    idNuancier: 2,
  },
  {
    réference: "Aurore n°239",
    nuancier: "les pastels",
    rgb: "233,228,229",
    marque: "Sign'Nature",
    idNuancier: 2,
  },
  {
    réference: "Horizon n°240",
    nuancier: "les pastels",
    rgb: "214,215,225",
    marque: "Sign'Nature",
    idNuancier: 2,
  },
  {
    réference: "Gris de Lin n°241",
    nuancier: "les pastels",
    rgb: "234,228,225",
    marque: "Sign'Nature",
    idNuancier: 2,
  },
  {
    réference: "Velours n°242",
    nuancier: "les pastels",
    rgb: "211,210,200",
    marque: "Sign'Nature",
    idNuancier: 2,
  },
  {
    réference: "Menthe Claire n°243",
    nuancier: "les pastels",
    rgb: "224,231,227",
    marque: "Sign'Nature",
    idNuancier: 2,
  },
  {
    réference: "Brin de Paille n°244",
    nuancier: "les pastels",
    rgb: "240,235,214",
    marque: "Sign'Nature",
    idNuancier: 2,
  },
  {
    réference: "Fleur de Coton n°245",
    nuancier: "les pastels",
    rgb: "238,228,214",
    marque: "Sign'Nature",
    idNuancier: 2,
  },
  {
    réference: "Vanille Délice n°246",
    nuancier: "les pastels",
    rgb: "242,227,201",
    marque: "Sign'Nature",
    idNuancier: 2,
  },
  {
    réference: "Bleuet n°247",
    nuancier: "les pastels",
    rgb: "205,217,226",
    marque: "Sign'Nature",
    idNuancier: 2,
  },
  {
    réference: "Agate n°248",
    nuancier: "Les lofts urbains",
    rgb: "89,99,129",
    marque: "Sign'Nature",
    idNuancier: 3,
  },
  {
    réference: "Zamak n°249",
    nuancier: "Les lofts urbains",
    rgb: "152,152,152",
    marque: "Sign'Nature",
    idNuancier: 3,
  },
  {
    réference: "Bauxite n°250",
    nuancier: "Les lofts urbains",
    rgb: "132,71,71",
    marque: "Sign'Nature",
    idNuancier: 3,
  },
  {
    réference: "Francium n°251",
    nuancier: "Les lofts urbains",
    rgb: "117,119,119",
    marque: "Sign'Nature",
    idNuancier: 3,
  },
  {
    réference: "Aluminium n°252",
    nuancier: "Les lofts urbains",
    rgb: "128,132,137",
    marque: "Sign'Nature",
    idNuancier: 3,
  },
  {
    réference: "Cobalt n°253",
    nuancier: "Les lofts urbains",
    rgb: "119,131,137",
    marque: "Sign'Nature",
    idNuancier: 3,
  },
  {
    réference: "Pectolite n°254",
    nuancier: "Les lofts urbains",
    rgb: "142,139,132",
    marque: "Sign'Nature",
    idNuancier: 3,
  },
  {
    réference: "Manganèse n°255",
    nuancier: "Les lofts urbains",
    rgb: "97,99,107",
    marque: "Sign'Nature",
    idNuancier: 3,
  },
  {
    réference: "Cesium n°256",
    nuancier: "Les lofts urbains",
    rgb: "145,132,117",
    marque: "Sign'Nature",
    idNuancier: 3,
  },
  {
    réference: "Diode n°257",
    nuancier: "Les lofts urbains",
    rgb: "91,87,87",
    marque: "Sign'Nature",
    idNuancier: 3,
  },
  {
    réference: "Rhodium n°258",
    nuancier: "Les lofts urbains",
    rgb: "113,117,123",
    marque: "Sign'Nature",
    idNuancier: 3,
  },
  {
    réference: "Titane n°259",
    nuancier: "Les lofts urbains",
    rgb: "111,111,111",
    marque: "Sign'Nature",
    idNuancier: 3,
  },
  {
    réference: "Argent n°260",
    nuancier: "Les lofts urbains",
    rgb: "154,156,153",
    marque: "Sign'Nature",
    idNuancier: 3,
  },
  {
    réference: "Ardoise n°261",
    nuancier: "Les lofts urbains",
    rgb: "81,99,119",
    marque: "Sign'Nature",
    idNuancier: 3,
  },
  {
    réference: "Tungstène n°262",
    nuancier: "Les lofts urbains",
    rgb: "101,115,117",
    marque: "Sign'Nature",
    idNuancier: 3,
  },
  {
    réference: "Bismuth n°263",
    nuancier: "Les lofts urbains",
    rgb: "139,139,139",
    marque: "Sign'Nature",
    idNuancier: 3,
  },
  {
    réference: "Acier n°264",
    nuancier: "Les lofts urbains",
    rgb: "124,135,155",
    marque: "Sign'Nature",
    idNuancier: 3,
  },
  {
    réference: "Adamite n°265",
    nuancier: "Les lofts urbains",
    rgb: "77,170,172",
    marque: "Sign'Nature",
    idNuancier: 3,
  },
  {
    réference: "Zinc n°266",
    nuancier: "Les lofts urbains",
    rgb: "95,119,132",
    marque: "Sign'Nature",
    idNuancier: 3,
  },
  {
    réference: "Platine n°267",
    nuancier: "Les lofts urbains",
    rgb: "160,167,171",
    marque: "Sign'Nature",
    idNuancier: 3,
  },
  {
    réference: "Etain n°268",
    nuancier: "Les lofts urbains",
    rgb: "131,127,121",
    marque: "Sign'Nature",
    idNuancier: 3,
  },
  {
    réference: "Fluorite n°269",
    nuancier: "Les lofts urbains",
    rgb: "166,179,189",
    marque: "Sign'Nature",
    idNuancier: 3,
  },
  {
    réference: "Nickel n°270",
    nuancier: "Les lofts urbains",
    rgb: "125,115,105",
    marque: "Sign'Nature",
    idNuancier: 3,
  },
  {
    réference: "Plomb n°271",
    nuancier: "Les lofts urbains",
    rgb: "141,148,152",
    marque: "Sign'Nature",
    idNuancier: 3,
  },
  {
    réference: "Silicium n°272",
    nuancier: "Les lofts urbains",
    rgb: "95,95,93",
    marque: "Sign'Nature",
    idNuancier: 3,
  },
  {
    réference: "Kermésite n°273",
    nuancier: "Les lofts urbains",
    rgb: "164,65,73",
    marque: "Sign'Nature",
    idNuancier: 3,
  },
  {
    réference: "Kalypso n°274",
    nuancier: "Les incontournables",
    rgb: "53,177,192",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Mars n°275",
    nuancier: "Les incontournables",
    rgb: "171,167,172",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Luna n°276",
    nuancier: "Les incontournables",
    rgb: "228,226,226",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Athena n°277",
    nuancier: "Les incontournables",
    rgb: "179,196,75",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Polaris n°278",
    nuancier: "Les incontournables",
    rgb: "155,182,216",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Minerve n°279",
    nuancier: "Les incontournables",
    rgb: "245,228,179",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Pégase n°280",
    nuancier: "Les incontournables",
    rgb: "226,168,57",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Iskandar n°281",
    nuancier: "Les incontournables",
    rgb: "217,205,186",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Neptune n°282",
    nuancier: "Les incontournables",
    rgb: "131,121,168",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Pluton n°283",
    nuancier: "Les incontournables",
    rgb: "247,211,103",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Saturne n°284",
    nuancier: "Les incontournables",
    rgb: "193,192,198",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Uranus n°285",
    nuancier: "Les incontournables",
    rgb: "119,205,206",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Sérapis n°286",
    nuancier: "Les incontournables",
    rgb: "136,183,220",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Oméga n°287",
    nuancier: "Les incontournables",
    rgb: "234,199,117",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Sôma n°288",
    nuancier: "Les incontournables",
    rgb: "172,65,63",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Temse n°289",
    nuancier: "Les incontournables",
    rgb: "225,206,185",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Ariane n°290",
    nuancier: "Les incontournables",
    rgb: "125,139,152",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Psyché n°291",
    nuancier: "Les incontournables",
    rgb: "152,150,143",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Sémélé n°292",
    nuancier: "Les incontournables",
    rgb: "196,191,200",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Andromède n°293",
    nuancier: "Les incontournables",
    rgb: "175,193,93",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Cassiopée n°294",
    nuancier: "Les incontournables",
    rgb: "133,149,207",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Céphée n°295",
    nuancier: "Les incontournables",
    rgb: "245,207,115",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Chiron n°296",
    nuancier: "Les incontournables",
    rgb: "204,87,97",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Orion n°297",
    nuancier: "Les incontournables",
    rgb: "208,196,187",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Perséphone n°298",
    nuancier: "Les incontournables",
    rgb: "221,198,202",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Odin n°299",
    nuancier: "Les incontournables",
    rgb: "158,158,156",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Loki n°300",
    nuancier: "Les incontournables",
    rgb: "164,155,167",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Freyja n°301",
    nuancier: "Les incontournables",
    rgb: "105,198,184",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Tyr n°302",
    nuancier: "Les incontournables",
    rgb: "129,154,199",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Antarès n°303",
    nuancier: "Les incontournables",
    rgb: "254,154,43",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Njôrd n°304",
    nuancier: "Les incontournables",
    rgb: "188,65,61",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Asgard n°305",
    nuancier: "Les incontournables",
    rgb: "170,158,146",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Epsilon n°306",
    nuancier: "Les incontournables",
    rgb: "192,160,174",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Héra n°307",
    nuancier: "Les incontournables",
    rgb: "150,146,151",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Ammon n°308",
    nuancier: "Les incontournables",
    rgb: "146,133,146",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Aboukis n°309",
    nuancier: "Les incontournables",
    rgb: "133,215,9",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Hathor n°310",
    nuancier: "Les incontournables",
    rgb: "53,150,191",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Horus n°311",
    nuancier: "Les incontournables",
    rgb: "244,208,85",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Isis n°312",
    nuancier: "Les incontournables",
    rgb: "216,121,53",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Mercure n°313",
    nuancier: "Les incontournables",
    rgb: "176,157,137",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Ankor n°314",
    nuancier: "Les incontournables",
    rgb: "155,119,131",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Thot n°315",
    nuancier: "Les incontournables",
    rgb: "143,142,140",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Artémis n°316",
    nuancier: "Les incontournables",
    rgb: "123,107,123",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Dionysos n°317",
    nuancier: "Les incontournables",
    rgb: "123,131,79",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Zelda n°318",
    nuancier: "Les incontournables",
    rgb: "21,148,177",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Hermès n°319",
    nuancier: "Les incontournables",
    rgb: "253,176,61",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Trano n°320",
    nuancier: "Les incontournables",
    rgb: "141,65,75",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Déméter n°321",
    nuancier: "Les incontournables",
    rgb: "221,190,157",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Shivah n°322",
    nuancier: "Les incontournables",
    rgb: "127,95,109",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Lucifer n°323",
    nuancier: "Les incontournables",
    rgb: "131,128,134",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Vénus n°324",
    nuancier: "Les incontournables",
    rgb: "128,107,133",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Phobos n°325",
    nuancier: "Les incontournables",
    rgb: "117,179,53",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Poséidon n°326",
    nuancier: "Les incontournables",
    rgb: "91,121,173",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Astrée n°327",
    nuancier: "Les incontournables",
    rgb: "249,187,0",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Eros n°328",
    nuancier: "Les incontournables",
    rgb: "158,67,65",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Gaïa n°329",
    nuancier: "Les incontournables",
    rgb: "141,133,125",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Walkyrie n°330",
    nuancier: "Les incontournables",
    rgb: "193,183,172",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Hadès n°331",
    nuancier: "Les incontournables",
    rgb: "113,111,117",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Aphrodite n°332",
    nuancier: "Les incontournables",
    rgb: "109,83,95",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Osiris n°333",
    nuancier: "Les incontournables",
    rgb: "107,119,65",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Janus n°334",
    nuancier: "Les incontournables",
    rgb: "25,99,107",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Apollon n°335",
    nuancier: "Les incontournables",
    rgb: "236,111,57",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Héphaïstos n°336",
    nuancier: "Les incontournables",
    rgb: "117,73,69",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Junon n°337",
    nuancier: "Les incontournables",
    rgb: "125,111,105",
    marque: "Sign'Nature",
    idNuancier: 4,
  },
  {
    réference: "Curcuma n°104",
    nuancier: "Végétal",
    rgb: "175,177,163",
    idNuancier: 5,
  },
  {
    réference: "Fluorine n°94",
    nuancier: "Minéral",
    rgb: "96,96,94",
    idNuancier: 6,
  },
  {
    réference: "Bass n°145",
    nuancier: "Mer",
    rgb: "203,213,225",
    idNuancier: 7,
  },
  {
    réference: "Chakma n°56",
    nuancier: "Terre",
    rgb: "132,71,70",
    idNuancier: 8,
  },
  {
    réference: "Bornite n°68",
    nuancier: "Minéral",
    rgb: "207,206,211",
    idNuancier: 6,
  },
  {
    réference: "Varazze n°59",
    nuancier: "Terre",
    rgb: "203,152,123",
    idNuancier: 8,
  },
  {
    réference: "Etésien n°12",
    nuancier: "Air",
    rgb: "233,229,226",
    idNuancier: 9,
  },
  {
    réference: "Solane n°29",
    nuancier: "Air",
    rgb: "237,227,217",
    idNuancier: 9,
  },
  {
    réference: "Take n°30",
    nuancier: "Air",
    rgb: "235,223,211",
    idNuancier: 9,
  },
  {
    réference: "Chiapas n°64",
    nuancier: "Terre",
    rgb: "83,74,75",
    idNuancier: 8,
  },
  {
    réference: "Hurricane n°17",
    nuancier: "Air",
    rgb: "234,234,224",
    idNuancier: 9,
  },
  {
    réference: "Campo n°44",
    nuancier: "Terre",
    rgb: "173,153,129",
    idNuancier: 8,
  },
  {
    réference: "Cortes n°149",
    nuancier: "Mer",
    rgb: "178,188,224",
    idNuancier: 7,
  },
  {
    réference: "Baguio n°4",
    nuancier: "Air",
    rgb: "242,225,195",
    idNuancier: 9,
  },
  {
    réference: "Barents n°133",
    nuancier: "Mer",
    rgb: "208,226,226",
    idNuancier: 7,
  },
  {
    réference: "Aloes n°98",
    nuancier: "Végétal",
    rgb: "226,229,220",
    idNuancier: 5,
  },
  {
    réference: "Amiata n°171",
    nuancier: "Feu",
    rgb: "231,189,131",
    idNuancier: 10,
  },
  {
    réference: "Maloja n°22",
    nuancier: "Air",
    rgb: "232,221,199",
    idNuancier: 9,
  },
  {
    réference: "Stromboli n°173",
    nuancier: "Feu",
    rgb: "209,169,133",
    idNuancier: 10,
  },
  {
    réference: "Messina n°58",
    nuancier: "Terre",
    rgb: "181,134,116",
    idNuancier: 8,
  },
  {
    réference: "Makassar n°160",
    nuancier: "Mer",
    rgb: "67,81,90",
    idNuancier: 7,
  },
  {
    réference: "Anubis n°55",
    nuancier: "Terre",
    rgb: "224,172,150",
    idNuancier: 8,
  },
  {
    réference: "Roselle n°112",
    nuancier: "Végétal",
    rgb: "125,172,164",
    idNuancier: 5,
  },
  {
    réference: "Argentite n°67",
    nuancier: "Minéral",
    rgb: "214,217,224",
    idNuancier: 6,
  },
  {
    réference: "Magnétite n°83",
    nuancier: "Minéral",
    rgb: "207,202,196",
    idNuancier: 6,
  },
  {
    réference: "Bone n°159",
    nuancier: "Mer",
    rgb: "70,85,116",
    idNuancier: 7,
  },
  {
    réference: "Cubé n°115",
    nuancier: "Végétal",
    rgb: "225,208,162",
    idNuancier: 5,
  },
  {
    réference: "Zanzan n°60",
    nuancier: "Terre",
    rgb: "206,170,134",
    idNuancier: 8,
  },
  {
    réference: "Céréus n°101",
    nuancier: "Végétal",
    rgb: "213,218,211",
    idNuancier: 5,
  },
  {
    réference: "Bamus n°184",
    nuancier: "Feu",
    rgb: "96,70,99",
    idNuancier: 10,
  },
  {
    réference: "Adonide n°128",
    nuancier: "Végétal",
    rgb: "69,81,81",
    idNuancier: 5,
  },
  {
    réference: "Terceira n°180",
    nuancier: "Feu",
    rgb: "182,109,126",
    idNuancier: 10,
  },
  {
    réference: "Lazarev n°158",
    nuancier: "Mer",
    rgb: "62,60,99",
    idNuancier: 7,
  },
  {
    réference: "Calcite n°91",
    nuancier: "Minéral",
    rgb: "93,101,112",
    idNuancier: 6,
  },
  {
    réference: "Lyoko n°61",
    nuancier: "Terre",
    rgb: "172,116,67",
    idNuancier: 8,
  },
  {
    réference: "Sures n°25",
    nuancier: "Air",
    rgb: "240,229,223",
    idNuancier: 9,
  },
  {
    réference: "Cérusite n°92",
    nuancier: "Minéral",
    rgb: "157,156,151",
    idNuancier: 6,
  },
  {
    réference: "Vaudaire n°32",
    nuancier: "Air",
    rgb: "231,220,226",
    idNuancier: 9,
  },
  {
    réference: "Bisée n°27",
    nuancier: "Air",
    rgb: "239,230,225",
    idNuancier: 9,
  },
  {
    réference: "Arafura n°146",
    nuancier: "Mer",
    rgb: "169,186,204",
    idNuancier: 7,
  },
  {
    réference: "Ephédra n°103",
    nuancier: "Végétal",
    rgb: "189,194,188",
    idNuancier: 5,
  },
  {
    réference: "Seto n°151",
    nuancier: "Mer",
    rgb: "115,175,186",
    idNuancier: 7,
  },
  {
    réference: "Didas n°49",
    nuancier: "Terre",
    rgb: "225,220,217",
    idNuancier: 8,
  },
  {
    réference: "Rafale n°26",
    nuancier: "Air",
    rgb: "238,233,230",
    idNuancier: 9,
  },
  {
    réference: "Zamora n°50",
    nuancier: "Terre",
    rgb: "185,164,147",
    idNuancier: 8,
  },
  {
    réference: "Autal n°3",
    nuancier: "Air",
    rgb: "247,240,222",
    idNuancier: 9,
  },
  {
    réference: "Soufre n°65",
    nuancier: "Minéral",
    rgb: "214,219,222",
    idNuancier: 6,
  },
  {
    réference: "Levant n°21",
    nuancier: "Air",
    rgb: "233,230,221",
    idNuancier: 9,
  },
  {
    réference: "Chipilo n°43",
    nuancier: "Terre",
    rgb: "177,165,153",
    idNuancier: 8,
  },
  {
    réference: "Laptev n°138",
    nuancier: "Mer",
    rgb: "199,222,228",
    idNuancier: 7,
  },
  {
    réference: "Boléite n°85",
    nuancier: "Minéral",
    rgb: "198,203,197",
    idNuancier: 6,
  },
  {
    réference: "Laki n°170",
    nuancier: "Feu",
    rgb: "240,197,152",
    idNuancier: 10,
  },
  {
    réference: "Leste n°20",
    nuancier: "Air",
    rgb: "238,230,219",
    idNuancier: 9,
  },
  {
    réference: "Notus n°23",
    nuancier: "Air",
    rgb: "217,208,193",
    idNuancier: 9,
  },
  {
    réference: "Accona n°63",
    nuancier: "Terre",
    rgb: "117,90,79",
    idNuancier: 8,
  },
  {
    réference: "Pico n°179",
    nuancier: "Feu",
    rgb: "196,82,115",
    idNuancier: 10,
  },
  {
    réference: "Labrador n°137",
    nuancier: "Mer",
    rgb: "225,234,231",
    idNuancier: 7,
  },
  {
    réference: "Aden n°144",
    nuancier: "Mer",
    rgb: "115,150,182",
    idNuancier: 7,
  },
  {
    réference: "Acanthite n°66",
    nuancier: "Minéral",
    rgb: "213,213,211",
    idNuancier: 6,
  },
  {
    réference: "Kola n°106",
    nuancier: "Végétal",
    rgb: "190,191,177",
    idNuancier: 5,
  },
  {
    réference: "Jussie n°124",
    nuancier: "Végétal",
    rgb: "111,121,110",
    idNuancier: 5,
  },
  {
    réference: "Arenzano n°33",
    nuancier: "Terre",
    rgb: "235,231,220",
    idNuancier: 8,
  },
  {
    réference: "Albe n°13",
    nuancier: "Air",
    rgb: "216,218,217",
    idNuancier: 9,
  },
  {
    réference: "Vulcano n°175",
    nuancier: "Feu",
    rgb: "206,97,66",
    idNuancier: 10,
  },
  {
    réference: "Vénétie n°42",
    nuancier: "Terre",
    rgb: "209,205,196",
    idNuancier: 8,
  },
  {
    réference: "Somov n°150",
    nuancier: "Mer",
    rgb: "89,110,177",
    idNuancier: 7,
  },
  {
    réference: "Martaban n°157",
    nuancier: "Mer",
    rgb: "89,110,177",
    idNuancier: 7,
  },
  {
    réference: "Ségovie n°36",
    nuancier: "Terre",
    rgb: "232,211,184",
    idNuancier: 8,
  },
  {
    réference: "Busserole n°110",
    nuancier: "Végétal",
    rgb: "171,183,169",
    idNuancier: 5,
  },
  {
    réference: "Mélice n°97",
    nuancier: "Végétal",
    rgb: "230,228,205",
    idNuancier: 5,
  },
  {
    réference: "Narage n°187",
    nuancier: "Feu",
    rgb: "190,107,91",
    idNuancier: 10,
  },
  {
    réference: "Manganite n°79",
    nuancier: "Minéral",
    rgb: "201,200,205",
    idNuancier: 6,
  },
  {
    réference: "Madilogo n°190",
    nuancier: "Feu",
    rgb: "150,69,66",
    idNuancier: 10,
  },
  {
    réference: "Spinelle n°81",
    nuancier: "Minéral",
    rgb: "204,212,214",
    idNuancier: 6,
  },
  {
    réference: "Katla n°169",
    nuancier: "Feu",
    rgb: "242,216,179",
    idNuancier: 10,
  },
  {
    réference: "Ammi n°123",
    nuancier: "Végétal",
    rgb: "136,133,116",
    idNuancier: 5,
  },
  {
    réference: "Bola n°185",
    nuancier: "Feu",
    rgb: "196,175,172",
    idNuancier: 10,
  },
  {
    réference: "Joran n°18",
    nuancier: "Air",
    rgb: "233,236,229",
    idNuancier: 9,
  },
  {
    réference: "Urville n°142",
    nuancier: "Mer",
    rgb: "214,219,222",
    idNuancier: 7,
  },
  {
    réference: "Acore n°99",
    nuancier: "Végétal",
    rgb: "219,221,208",
    idNuancier: 5,
  },
  {
    réference: "Chergui n°11",
    nuancier: "Air",
    rgb: "226,219,209",
    idNuancier: 9,
  },
  {
    réference: "Natal n°57",
    nuancier: "Terre",
    rgb: "209,168,150",
    idNuancier: 8,
  },
  {
    réference: "Mindanao n°153",
    nuancier: "Mer",
    rgb: "150,193,212",
    idNuancier: 7,
  },
  {
    réference: "Cimifuga n°102",
    nuancier: "Végétal",
    rgb: "211,217,207",
    idNuancier: 5,
  },
  {
    réference: "Baluan n°183",
    nuancier: "Feu",
    rgb: "146,82,117",
    idNuancier: 10,
  },
  {
    réference: "Galéga n°105",
    nuancier: "Végétal",
    rgb: "212,207,188",
    idNuancier: 5,
  },
  {
    réference: "Tivano n°31",
    nuancier: "Air",
    rgb: "234,215,200",
    idNuancier: 9,
  },
  {
    réference: "Graphite n°96",
    nuancier: "Minéral",
    rgb: "60,60,60",
    idNuancier: 6,
  },
  {
    réference: "Dolomite n°93",
    nuancier: "Minéral",
    rgb: "119,117,118",
    idNuancier: 6,
  },
  {
    réference: "Anadyr n°154",
    nuancier: "Mer",
    rgb: "113,141,155",
    idNuancier: 7,
  },
  {
    réference: "Rutile n°80",
    nuancier: "Minéral",
    rgb: "186,186,186",
    idNuancier: 6,
  },
  {
    réference: "Sophora n°114",
    nuancier: "Végétal",
    rgb: "199,200,160",
    idNuancier: 5,
  },
  {
    réference: "Beaufort n°134",
    nuancier: "Mer",
    rgb: "190,208,220",
    idNuancier: 7,
  },
  {
    réference: "Seram n°148",
    nuancier: "Mer",
    rgb: "158,188,224",
    idNuancier: 7,
  },
  {
    réference: "Catalpa n°125",
    nuancier: "Végétal",
    rgb: "127,110,92",
    idNuancier: 5,
  },
  {
    réference: "Koro n°152",
    nuancier: "Mer",
    rgb: "55,107,121",
    idNuancier: 7,
  },
  {
    réference: "Fundy n°156",
    nuancier: "Mer",
    rgb: "77,127,152",
    idNuancier: 7,
  },
  {
    réference: "Etna n°172",
    nuancier: "Feu",
    rgb: "208,167,113",
    idNuancier: 10,
  },
  {
    réference: "Aragonite n°89",
    nuancier: "Minéral",
    rgb: "135,142,150",
    idNuancier: 6,
  },
  {
    réference: "Taveuni n°181",
    nuancier: "Feu",
    rgb: "165,75,101",
    idNuancier: 10,
  },
  {
    réference: "Sylvanite n°74",
    nuancier: "Minéral",
    rgb: "212,209,216",
    idNuancier: 6,
  },
  {
    réference: "Flores n°177",
    nuancier: "Feu",
    rgb: "235,220,227",
    idNuancier: 10,
  },
  {
    réference: "Tauhara n°182",
    nuancier: "Feu",
    rgb: "143,55,77",
    idNuancier: 10,
  },
  {
    réference: "Orme n°109",
    nuancier: "Végétal",
    rgb: "173,188,159",
    idNuancier: 5,
  },
  {
    réference: "Belize n°45",
    nuancier: "Terre",
    rgb: "159,136,120",
    idNuancier: 8,
  },
  {
    réference: "Corvo n°176",
    nuancier: "Feu",
    rgb: "250,134,77",
    idNuancier: 10,
  },
  {
    réference: "Koranga n°189",
    nuancier: "Feu",
    rgb: "183,60,78",
    idNuancier: 10,
  },
  {
    réference: "Corindon n°76",
    nuancier: "Minéral",
    rgb: "189,174,169",
    idNuancier: 6,
  },
  {
    réference: "Vésuve n°174",
    nuancier: "Feu",
    rgb: "213,131,81",
    idNuancier: 10,
  },
  {
    réference: "Sidérite n°95",
    nuancier: "Minéral",
    rgb: "71,75,78",
    idNuancier: 6,
  },
  {
    réference: "Shamal n°28",
    nuancier: "Air",
    rgb: "242,231,227",
    idNuancier: 9,
  },
  {
    réference: "Auro n°14",
    nuancier: "Air",
    rgb: "215,213,214",
    idNuancier: 9,
  },
  {
    réference: "Manam n°192",
    nuancier: "Feu",
    rgb: "107,67,67",
    idNuancier: 10,
  },
  {
    réference: "Arabis n°38",
    nuancier: "Terre",
    rgb: "177,143,106",
    idNuancier: 8,
  },
  {
    réference: "Séné n°113",
    nuancier: "Végétal",
    rgb: "217,214,181",
    idNuancier: 5,
  },
  {
    réference: "Tamier n°122",
    nuancier: "Végétal",
    rgb: "142,138,111",
    idNuancier: 5,
  },
  {
    réference: "Cambay n°155",
    nuancier: "Mer",
    rgb: "100,137,156",
    idNuancier: 7,
  },
  {
    réference: "Andaman n°143",
    nuancier: "Mer",
    rgb: "101,135,163",
    idNuancier: 7,
  },
  {
    réference: "Santarem n°37",
    nuancier: "Terre",
    rgb: "222,201,174",
    idNuancier: 8,
  },
  {
    réference: "Langila n°186",
    nuancier: "Feu",
    rgb: "154,97,90",
    idNuancier: 10,
  },
  {
    réference: "Lompoul n°46",
    nuancier: "Terre",
    rgb: "137,119,109",
    idNuancier: 8,
  },
  {
    réference: "Casamance n°34",
    nuancier: "Terre",
    rgb: "222,206,190",
    idNuancier: 8,
  },
  {
    réference: "Pago n°188",
    nuancier: "Feu",
    rgb: "162,58,67",
    idNuancier: 10,
  },
  {
    réference: "Maca n°107",
    nuancier: "Végétal",
    rgb: "191,205,192",
    idNuancier: 5,
  },
  {
    réference: "Maté n°108",
    nuancier: "Végétal",
    rgb: "183,197,180",
    idNuancier: 5,
  },
  {
    réference: "Victory n°191",
    nuancier: "Feu",
    rgb: "127,63,64",
    idNuancier: 10,
  },
  {
    réference: "Chromite n°82",
    nuancier: "Minéral",
    rgb: "205,205,195",
    idNuancier: 6,
  },
  {
    réference: "Enargite n°75",
    nuancier: "Minéral",
    rgb: "212,203,204",
    idNuancier: 6,
  },
  {
    réference: "Veracruz n°62",
    nuancier: "Terre",
    rgb: "185,119,84",
    idNuancier: 8,
  },
  {
    réference: "Breva n°9",
    nuancier: "Air",
    rgb: "234,231,226",
    idNuancier: 9,
  },
  {
    réference: "Soria n°41",
    nuancier: "Terre",
    rgb: "233,225,214",
    idNuancier: 8,
  },
  {
    réference: "Réalgar n°73",
    nuancier: "Minéral",
    rgb: "214,212,215",
    idNuancier: 6,
  },
  {
    réference: "Halite n°88",
    nuancier: "Minéral",
    rgb: "156,170,170",
    idNuancier: 6,
  },
  {
    réference: "Cers n°10",
    nuancier: "Air",
    rgb: "230,223,213",
    idNuancier: 9,
  },
  {
    réference: "Sulu n°147",
    nuancier: "Mer",
    rgb: "179,210,238",
    idNuancier: 7,
  },
  {
    réference: "Kubah n°19",
    nuancier: "Air",
    rgb: "232,228,217",
    idNuancier: 9,
  },
  {
    réference: "Gibbsite n°84",
    nuancier: "Minéral",
    rgb: "204,206,201",
    idNuancier: 6,
  },
  {
    réference: "Scotia n°141",
    nuancier: "Mer",
    rgb: "187,208,225",
    idNuancier: 7,
  },
  {
    réference: "Azurite n°90",
    nuancier: "Minéral",
    rgb: "105,103,114",
    idNuancier: 6,
  },
  {
    réference: "Pampero n°24",
    nuancier: "Air",
    rgb: "209,198,180",
    idNuancier: 9,
  },
  {
    réference: "Ricin n°111",
    nuancier: "Végétal",
    rgb: "139,179,153",
    idNuancier: 5,
  },
  {
    réference: "Aguateca n°35",
    nuancier: "Terre",
    rgb: "216,196,169",
    idNuancier: 8,
  },
  {
    réference: "Furnas n°178",
    nuancier: "Feu",
    rgb: "162,140,153",
    idNuancier: 10,
  },
  {
    réference: "Aralia n°100",
    nuancier: "Végétal",
    rgb: "223,220,203",
    idNuancier: 5,
  },
  {
    réference: "Pariou n°161",
    nuancier: "Feu",
    rgb: "239,222,178",
    idNuancier: 10,
  },
  {
    réference: "Nisyros n°167",
    nuancier: "Feu",
    rgb: "246,186,66",
    idNuancier: 10,
  },
  {
    réference: "Datura n°116",
    nuancier: "Végétal",
    rgb: "209,203,171",
    idNuancier: 5,
  },
  {
    réference: "Zou n°47",
    nuancier: "Terre",
    rgb: "117,103,100",
    idNuancier: 8,
  },
  {
    réference: "Blizzard n°6",
    nuancier: "Air",
    rgb: "225,209,186",
    idNuancier: 9,
  },
  {
    réference: "Cytise n°120",
    nuancier: "Végétal",
    rgb: "120,133,107",
    idNuancier: 5,
  },
  {
    réference: "Ziro n°54",
    nuancier: "Terre",
    rgb: "178,119,105",
    idNuancier: 8,
  },
  {
    réference: "Kara n°136",
    nuancier: "Mer",
    rgb: "178,192,205",
    idNuancier: 7,
  },
  {
    réference: "Helm n°16",
    nuancier: "Air",
    rgb: "220,214,202",
    idNuancier: 9,
  },
  {
    réference: "Pavin n°164",
    nuancier: "Feu",
    rgb: "247,218,148",
    idNuancier: 10,
  },
  {
    réference: "Charcot n°140",
    nuancier: "Mer",
    rgb: "118,151,160",
    idNuancier: 7,
  },
  {
    réference: "Wendel n°139",
    nuancier: "Mer",
    rgb: "158,199,201",
    idNuancier: 7,
  },
  {
    réference: "Aconit n°118",
    nuancier: "Végétal",
    rgb: "168,150,100",
    idNuancier: 5,
  },
  {
    réference: "Hekla n°168",
    nuancier: "Feu",
    rgb: "243,176,97",
    idNuancier: 10,
  },
  {
    réference: "Cos n°165",
    nuancier: "Feu",
    rgb: "231,195,83",
    idNuancier: 10,
  },
  {
    réference: "Cobaltite n°71",
    nuancier: "Minéral",
    rgb: "186,190,199",
    idNuancier: 6,
  },
  {
    réference: "Grigale n°15",
    nuancier: "Air",
    rgb: "212,205,189",
    idNuancier: 9,
  },
  {
    réference: "Pyrite n°69",
    nuancier: "Minéral",
    rgb: "202,207,213",
    idNuancier: 6,
  },
  {
    réference: "Hématite n°77",
    nuancier: "Minéral",
    rgb: "199,194,188",
    idNuancier: 6,
  },
  {
    réference: "Lassolas n°162",
    nuancier: "Feu",
    rgb: "227,209,161",
    idNuancier: 10,
  },
  {
    réference: "Aquilon n°1",
    nuancier: "Air",
    rgb: "240,239,235",
    idNuancier: 9,
  },
  {
    réference: "Davis n°135",
    nuancier: "Mer",
    rgb: "188,204,220",
    idNuancier: 7,
  },
  {
    réference: "Masone n°53",
    nuancier: "Terre",
    rgb: "185,156,142",
    idNuancier: 8,
  },
  {
    réference: "Alboran n°130",
    nuancier: "Mer",
    rgb: "218,226,228",
    idNuancier: 7,
  },
  {
    réference: "Azov n°129",
    nuancier: "Mer",
    rgb: "223,224,226",
    idNuancier: 7,
  },
  {
    réference: "Tikal n°39",
    nuancier: "Terre",
    rgb: "125,89,73",
    idNuancier: 8,
  },
  {
    réference: "Nubie n°51",
    nuancier: "Terre",
    rgb: "218,200,190",
    idNuancier: 8,
  },
  {
    réference: "Iboga n°117",
    nuancier: "Végétal",
    rgb: "193,182,126",
    idNuancier: 5,
  },
  {
    réference: "Oyat n°127",
    nuancier: "Végétal",
    rgb: "93,102,101",
    idNuancier: 5,
  },
  {
    réference: "Galène n°72",
    nuancier: "Minéral",
    rgb: "186,193,211",
    idNuancier: 6,
  },
  {
    réference: "Caucase n°40",
    nuancier: "Terre",
    rgb: "109,87,76",
    idNuancier: 8,
  },
  {
    réference: "Sargasses n°132",
    nuancier: "Mer",
    rgb: "210,219,216",
    idNuancier: 7,
  },
  {
    réference: "Methana n°166",
    nuancier: "Feu",
    rgb: "231,183,57",
    idNuancier: 10,
  },
  {
    réference: "Nugère n°163",
    nuancier: "Feu",
    rgb: "235,197,134",
    idNuancier: 10,
  },
  {
    réference: "Ilménite n°78",
    nuancier: "Minéral",
    rgb: "196,193,188",
    idNuancier: 6,
  },
  {
    réference: "Bolon n°7",
    nuancier: "Air",
    rgb: "220,205,186",
    idNuancier: 9,
  },
  {
    réference: "Iroise n°131",
    nuancier: "Mer",
    rgb: "210,219,218",
    idNuancier: 7,
  },
  {
    réference: "Mandé n°52",
    nuancier: "Terre",
    rgb: "198,176,165",
    idNuancier: 8,
  },
  {
    réference: "Bise n°5",
    nuancier: "Air",
    rgb: "243,231,207",
    idNuancier: 9,
  },
  {
    réference: "Auster n°2",
    nuancier: "Air",
    rgb: "238,231,221",
    idNuancier: 9,
  },
  {
    réference: "Nicelle n°121",
    nuancier: "Végétal",
    rgb: "171,173,152",
    idNuancier: 5,
  },
  {
    réference: "Cryolithe n°86",
    nuancier: "Minéral",
    rgb: "173,173,163",
    idNuancier: 6,
  },
  {
    réference: "Malachite n°87",
    nuancier: "Minéral",
    rgb: "142,149,142",
    idNuancier: 6,
  },
  {
    réference: "Mélilot n°126",
    nuancier: "Végétal",
    rgb: "210,219,218",
    idNuancier: 5,
  },
  {
    réference: "Bora n°8",
    nuancier: "Air",
    rgb: "207,191,168",
    idNuancier: 9,
  },
  {
    réference: "Ciabre n°70",
    nuancier: "Minéral",
    rgb: "171,182,188",
    idNuancier: 9,
  },
  {
    réference: "Calla n°119",
    nuancier: "Végétal",
    rgb: "141,152,112",
    idNuancier: 5,
  },
  {
    réference: "Lagos n°48",
    nuancier: "Terre",
    rgb: "112,103,94",
    idNuancier: 8,
  },
];

export { IColorWall };

export default colorWall;
