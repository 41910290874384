import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-5e808864"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = ["id"];
var _hoisted_2 = {
  class: "modal-header"
};
var _hoisted_3 = {
  class: "d-flex align-items-center"
};
var _hoisted_4 = {
  class: "mb-0"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_inline_svg = _resolveComponent("inline-svg");

  var _component_Close = _resolveComponent("Close");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", {
    class: "modals",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.setModal('');
    })
  }), _createElementVNode("div", {
    class: "mx-auto modal-content",
    style: _normalizeStyle({
      maxWidth: _ctx.maxWidth
    })
  }, [_createElementVNode("div", {
    class: "modal-content mx-auto",
    id: _ctx.id
  }, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_ctx.displayArrow ? (_openBlock(), _createBlock(_component_inline_svg, {
    key: 0,
    style: {
      "cursor": "pointer"
    },
    class: "me-2",
    onClick: _ctx.returnFunction,
    src: "/svg/icons/arraw.svg"
  }, null, 8, ["onClick"])) : _createCommentVNode("", true), _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.title), 1)]), _createVNode(_component_Close, {
    "close-button": _ctx.closeButton,
    class: "close-btn",
    onCloseModal: _cache[1] || (_cache[1] = function () {
      return _ctx.setModal('');
    })
  }, null, 8, ["close-button"])]), _createElementVNode("div", {
    class: "modal-body",
    style: _normalizeStyle(_ctx.isMarginAuto ? 'margin: auto' : '')
  }, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)], 4)], 8, _hoisted_1)], 4)], 64);
}