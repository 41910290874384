import { defineComponent } from "vue";
import UploadIconSVG from "../../../assets/svg/UploadIconSVG.vue";

var __default__ = defineComponent({
  name: "box-little-component",
  props: {
    src: {
      type: String,
      default: "svg/icons/image-file-thummbnail.svg"
    },
    alt: {
      type: String,
      default: "image-icon"
    },
    title: {
      type: String,
      required: false
    },
    description: {
      type: String,
      required: false
    },
    background: {
      type: String,
      default: "#fff4dc"
    },
    uploadImage: {
      type: Function
    }
  },
  components: {
    UploadIconSVG: UploadIconSVG
  },
  setup: function setup() {
    var color = process.env.VUE_APP_COLOR;
    return {
      color: color
    };
  }
});

import { useCssVars as _useCssVars } from 'vue';

var __injectCSSVars__ = function __injectCSSVars__() {
  _useCssVars(function (_ctx) {
    return {
      "7b58bb31": _ctx.color
    };
  });
};

var __setup__ = __default__.setup;
__default__.setup = __setup__ ? function (props, ctx) {
  __injectCSSVars__();

  return __setup__(props, ctx);
} : __injectCSSVars__;
export default __default__;