import { defineComponent } from "vue";
export default defineComponent({
  props: {
    title: {
      type: String
    },
    imageSrc: {
      type: String
    },
    imageAlt: {
      type: String
    },
    filter: {
      type: Function
    },
    condition: {
      type: Boolean
    }
  },
  components: {},
  setup: function setup() {
    return {};
  }
});