import { defineComponent } from "vue";
var DEBUG = process.env.VUE_APP_DEBUG == "true";
export default defineComponent({
  setup: function setup() {
    var color = process.env.VUE_APP_COLOR;
    return {
      DEBUG: DEBUG,
      color: color
    };
  }
});