import { defineComponent, computed } from "vue";
import { useStore } from "vuex"; //Modals

import SelectFloor from "../modals/modal-component/SelectFloor.vue";
import SelectWall from "../modals/modal-component/SelectWall.vue";
import SelectEspace from "./modal-component/SelectEspace.vue";
import ZoomModal from "./modal-component/ZoomModal.vue";
export default defineComponent({
  components: {
    SelectFloor: SelectFloor,
    SelectWall: SelectWall,
    SelectEspace: SelectEspace,
    ZoomModal: ZoomModal
  },
  setup: function setup() {
    var store = useStore();
    var modalState = computed(function () {
      return store.getters.getModalState;
    });
    return {
      modalState: modalState
    };
  }
});