import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "@/assets/sass/style.scss";
import "@/assets/sass/global.scss";

import { initInlineSvg } from "@/core/plugins/inline-svg";
import VueSocialSharing from "vue-social-sharing";
import { VueClipboard } from "@soerenmartius/vue3-clipboard";
import VueGtag from "vue-gtag-next";

const app = createApp(App);

app.use(store);
app.use(VueSocialSharing, {
  networks: {
    fakeblock: "https://fakeblock.com/share?url=@url&title=@title",
  },
});

app.use(VueGtag, {
  property: {
    id: process.env.VUE_APP_GOOGLE_ANALYTICS_TRACKER_ID,
  },
});

app.use(router);
app.use(VueClipboard);
initInlineSvg(app);
app.mount("#app");
