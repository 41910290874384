import store from "@/store/index";
import { Actions } from "@/store/enums/StoreEnums";

export const setModal = (type: string, payload = {}): void => {
  store.dispatch(Actions.FETCH_MODAL, {
    type: type,
    payload,
  });
};

export const setWallColor = (color: string): void => {
  store.dispatch(Actions.FETCH_WALL_COLOR, color);
};

export const setFloorPattern = (fpatt: string): void => {
  store.dispatch(Actions.FETCH_FLOOR_PATTERN, fpatt);
};

export const setImgName = (img_name: string): void => {
  store.dispatch(Actions.FETCH_IMG_NAME, img_name);
};
export const getImageUrl = (imageName: string): string =>{
  let u = `${process.env.VUE_APP_API_URL}process-image/${imageName}`;
  if (!u.includes('http')){
        u = `https://${window.location.host}${u}`
  }
  return u
}
export const buildTreatedImageUrl = (
  imageName: string,
  { color, floor }: { color?: string; floor?: string }
): string => {
  const searchParams = new URLSearchParams()
  const u = getImageUrl(imageName)
  if (color) searchParams.set("color", color);
  if (floor) searchParams.set("fpatt", floor);
  return u + "?" + searchParams.toString();
};
