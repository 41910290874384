import { defineComponent } from "vue";
import UploadSVG from "../assets/svg/UploadSVG.vue";
import FloorSVG from "../assets/svg/FloorSVG.vue";
import WallSVG from "../assets/svg/WallSVG.vue";
import CompareSVG from "../assets/svg/CompareSVG.vue";

var __default__ = defineComponent({
  name: "menu-left",
  components: {
    UploadSVG: UploadSVG,
    FloorSVG: FloorSVG,
    WallSVG: WallSVG,
    CompareSVG: CompareSVG
  },
  props: {
    isCompare: {
      type: Object,
      required: true
    },
    handleCompare: {
      type: Function,
      required: true
    },
    openModalFloor: {
      type: Function,
      required: true
    },
    openModalWall: {
      type: Function,
      required: true
    },
    openModalEspace: {
      type: Function,
      required: true
    }
  },
  setup: function setup() {
    var color = process.env.VUE_APP_COLOR;
    return {
      color: color
    };
  }
});

import { useCssVars as _useCssVars } from 'vue';

var __injectCSSVars__ = function __injectCSSVars__() {
  _useCssVars(function (_ctx) {
    return {
      "37df6d36": _ctx.color
    };
  });
};

var __setup__ = __default__.setup;
__default__.setup = __setup__ ? function (props, ctx) {
  __injectCSSVars__();

  return __setup__(props, ctx);
} : __injectCSSVars__;
export default __default__;