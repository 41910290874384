import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-94acec34"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  for: "file",
  class: "upload-button-container",
  style: "position: relative;"
};
var _hoisted_2 = {
  class: "upload-button-pargraph"
};
var _hoisted_3 = {
  class: "upload-button-description"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_UploadIconSVG = _resolveComponent("UploadIconSVG");

  return _openBlock(), _createElementBlock("label", _hoisted_1, [_createElementVNode("label", {
    style: _normalizeStyle("\n      width: 100%; \n      height: 100%; \n      background-color: ".concat(_ctx.color, "; \n      position: absolute; \n      z-Index: 0; \n      border-radius: 6px; \n      opacity: 0.2"))
  }, null, 4), _createVNode(_component_UploadIconSVG, {
    fill: _ctx.color
  }, null, 8, ["fill"]), _createElementVNode("p", _hoisted_2, [_createTextVNode(_toDisplayString(_ctx.title), 1), _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.description), 1)]), _createElementVNode("input", {
    onChange: _cache[0] || (_cache[0] = //@ts-ignore
    function () {
      return _ctx.uploadImage && _ctx.uploadImage.apply(_ctx, arguments);
    }),
    style: {
      "display": "none"
    },
    type: "file",
    name: "file",
    id: "file",
    class: "inputfile",
    accept: "image/png, image/jpg, image/jpeg"
  }, null, 32)]);
}