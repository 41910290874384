enum Actions {
  // action types
  FETCH_MODAL = "fetchModal",
  FETCH_WALL_COLOR = "fetchWallColor",
  FETCH_FLOOR_PATTERN = "fetchFloorPattern",
  FETCH_IMG_NAME = "fetchImgName",
}

enum Mutations {
  // mutation types
  SET_MODAL_MUTATION = "setModalMutation",
  SET_WALL_COLOR_MUTATION = "setWallColor",
  SET_FLOOR_PATTERN_MUTATION = "setFloorPattern",
  SET_IMG_NAME_MUTATION = "setImgName",
}

export { Actions, Mutations };
