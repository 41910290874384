import _slicedToArray from "/opt/buildhome/repo/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import { ImgComparisonSlider } from "@img-comparison-slider/vue";
import { ElNotification } from "element-plus";
import { computed, defineComponent, onMounted, watch } from "vue";
export default defineComponent({
  name: "ImageCompare",
  props: {
    firstImage: {
      type: String
    },
    secondImage: {
      type: String
    },
    loading: {
      type: Boolean
    }
  },
  components: {
    ImgComparisonSlider: ImgComparisonSlider
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var color = process.env.VUE_APP_COLOR;
    var loadingComp = computed({
      get: function get() {
        return props.loading;
      },
      set: function set(value) {
        emit("update:loading", value);
      }
    });

    var onError = function onError() {
      loadingComp.value = false;
      ElNotification({
        title: "Erreur de chargement",
        type: "error"
      });
    };

    watch(function () {
      return [props.firstImage, props.secondImage];
    }, function (_ref2) {
      var _ref3 = _slicedToArray(_ref2, 2),
          firstImage = _ref3[0],
          secondImage = _ref3[1];

      if (firstImage || secondImage) loadingComp.value = true;
    });
    onMounted(function () {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      try {
        var _document$querySelect, _document$querySelect2;

        var classImageComapre = (_document$querySelect = document.querySelector("img-comparison-slider")) === null || _document$querySelect === void 0 ? void 0 : (_document$querySelect2 = _document$querySelect.shadowRoot) === null || _document$querySelect2 === void 0 ? void 0 : _document$querySelect2.getElementById("first");

        if (classImageComapre && classImageComapre.style) {
          classImageComapre.style.left = "50%";
          classImageComapre.style.width = "fit-content";
          classImageComapre.style.transform = "translateX(calc(var(--exposure) * -1 - 50%))";
          classImageComapre.style.display = "block";
        }
      } catch (error) {
        console.warn(error);
      }
    });
    return {
      loadingComp: loadingComp,
      onError: onError,
      color: color
    };
  }
});