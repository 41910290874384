import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "../enums/StoreEnums";
/* eslint-disable  @typescript-eslint/no-explicit-any */
interface Modal {
  type: string;
  payload: any;
}
export interface ImageSettings {
  color?: string;
  fpatt?: string;
}

@Module
export default class ConfigModule extends VuexModule {
  modalState: Modal = { type: "", payload: {} };
  //wall color
  color = "";
  //floor pattern
  fpatt = "";
  history = [];
  img_name = process.env.VUE_APP_IMAGE_NAME || "salon-0.jpg";
  previousSettings: ImageSettings | null = null;

  get getModalState(): Modal {
    return this.modalState;
  }

  get getwallColor(): string {
    return this.color;
  }
  get getFloorPattern(): string {
    return this.fpatt;
  }
  get getImgName(): string {
    return this.img_name;
  }
  get getPreviousSettings(): ImageSettings | null {
    return this.previousSettings;
  }

  @Mutation
  [Mutations.SET_MODAL_MUTATION](payload: Modal): void {
    this.modalState = payload;
  }

  @Mutation
  [Mutations.SET_WALL_COLOR_MUTATION](payload: string): void {
    this.previousSettings = { color: this.color, fpatt: this.fpatt };
    this.color = payload;
  }

  @Mutation
  [Mutations.SET_FLOOR_PATTERN_MUTATION](payload: string): void {
    this.previousSettings = { color: this.color, fpatt: this.fpatt };
    this.fpatt = payload;
  }

  @Mutation
  [Mutations.SET_IMG_NAME_MUTATION](payload: string): void {
    this.previousSettings = null;
    this.img_name = payload;
  }

  @Action
  [Actions.FETCH_MODAL](payload: Modal): void {
    this.context.commit(Mutations.SET_MODAL_MUTATION, payload);
  }

  @Action
  [Actions.FETCH_WALL_COLOR](payload: string): void {
    this.context.commit(Mutations.SET_WALL_COLOR_MUTATION, payload);
  }

  @Action
  [Actions.FETCH_FLOOR_PATTERN](payload: string): void {
    this.context.commit(Mutations.SET_FLOOR_PATTERN_MUTATION, payload);
  }

  @Action
  [Actions.FETCH_IMG_NAME](payload: string): void {
    this.context.commit(Mutations.SET_IMG_NAME_MUTATION, payload);
  }
}
