import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-e0c2c8da"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  id: "menu-bottom"
};
var _hoisted_2 = {
  class: "d-flex"
};
var _hoisted_3 = {
  id: "btn-zoom",
  title: "Zoom"
};
var _hoisted_4 = {
  id: "btn-share",
  title: "Partager l'image"
};
var _hoisted_5 = {
  id: "btn-print",
  title: "Imprimer l'image"
};
var _hoisted_6 = {
  class: "rounded-end"
};
var _hoisted_7 = ["href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_ZoomSVG = _resolveComponent("ZoomSVG");

  var _component_ShareSVG = _resolveComponent("ShareSVG");

  var _component_ShareModal = _resolveComponent("ShareModal");

  var _component_PrintSVG = _resolveComponent("PrintSVG");

  var _component_DownloadSVG = _resolveComponent("DownloadSVG");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("ul", _hoisted_2, [_createElementVNode("li", {
    onClick: _cache[0] || (_cache[0] = //@ts-ignore
    function () {
      return _ctx.handleZoom && _ctx.handleZoom.apply(_ctx, arguments);
    }),
    class: "rounded-start"
  }, [_createElementVNode("a", _hoisted_3, [_createVNode(_component_ZoomSVG, {
    fill: _ctx.myColor,
    style: {
      "width": "30px",
      "align-self": "center",
      "height": "30px",
      "flex": "1"
    }
  }, null, 8, ["fill"])])]), _createVNode(_component_ShareModal, null, {
    default: _withCtx(function () {
      return [_createElementVNode("li", null, [_createElementVNode("a", _hoisted_4, [_createVNode(_component_ShareSVG, {
        fill: _ctx.myColor,
        style: {
          "width": "30px",
          "align-self": "center",
          "height": "30px",
          "flex": "1"
        }
      }, null, 8, ["fill"])])])];
    }),
    _: 1
  }), _createElementVNode("li", {
    onClick: _cache[1] || (_cache[1] = function () {
      return _ctx.printImage();
    })
  }, [_createElementVNode("a", _hoisted_5, [_createVNode(_component_PrintSVG, {
    fill: _ctx.myColor,
    style: {
      "width": "30px",
      "align-self": "center",
      "height": "30px",
      "flex": "1"
    }
  }, null, 8, ["fill"])])]), _createElementVNode("li", _hoisted_6, [_createElementVNode("a", {
    href: _ctx.sharingURL,
    id: "btn-download",
    title: "Télecharger l'image",
    target: "_blank",
    download: "result.jpeg"
  }, [_createVNode(_component_DownloadSVG, {
    fill: _ctx.myColor,
    style: {
      "align-self": "center",
      "height": "17px",
      "flex": "1"
    }
  }, null, 8, ["fill"])], 8, _hoisted_7)])])]);
}