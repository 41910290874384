import { App } from "vue";
import InlineSvg from "vue-inline-svg";

/**
 * Initialize Inline-Svg component
 * @param app vue instance
 */
/* eslint-disable  @typescript-eslint/no-explicit-any */
export function initInlineSvg(app: App<Element>): any {
  app.component("inline-svg", InlineSvg);
}
