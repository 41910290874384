interface IInfoNuancier {
  src: string;
  title: string;
  id: number;
  alt: string;
}

const infoNuancier: Array<IInfoNuancier> = [
  {
    src: "images/nuancier_petilliantes.png",
    alt: "nuancier_petilliantes",
    title: "PÉTILLANTES",
    id: 1,
  },
  {
    src: "images/nuancier_pastels.png",
    alt: "nuancier_pastels",
    title: "PASTELS",
    id: 2,
  },
  {
    src: "images/nuancier_urbains.png",
    alt: "nuancier_urbains",
    title: "LOFTS URBAINS",
    id: 3,
  },
  {
    src: "images/nuancier_incontournables.png",
    alt: "nuancier_incontournables",
    title: "INCONTOURNABLES",
    id: 4,
  },
  {
    src: "images/nuancier_végétal.png",
    alt: "nuancier_végétal",
    title: "VÉGÉTAL",
    id: 5,
  },
  {
    src: "images/nuancier_minéral.png",
    alt: "nuancier_minéral",
    title: "MINÉRAL",
    id: 6,
  },
  {
    src: "images/nuancier_mer.png",
    alt: "nuancier_mer",
    title: "MER",
    id: 7,
  },
  {
    src: "images/nuancier_terre.png",
    alt: "nuancier_terre",
    title: "TERRE",
    id: 8,
  },
  {
    src: "images/nuancier_air.png",
    alt: "nuancier_air",
    title: "AIR",
    id: 9,
  },
  {
    src: "images/nuancier_feu.png",
    alt: "nuancier_feu",
    title: "FEU",
    id: 10,
  },
];

export { IInfoNuancier };
export { infoNuancier };
