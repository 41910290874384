import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-42adb298"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = ["src", "alt"];
var _hoisted_2 = {
  class: "overlay rounded-bottom"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    style: {
      "cursor": "pointer"
    },
    class: _normalizeClass(["nuancier-color mb-5 m-3", [_ctx.condition && 'active-wall']]),
    onClick: _cache[0] || (_cache[0] = //@ts-ignore
    function () {
      return _ctx.filter && _ctx.filter.apply(_ctx, arguments);
    })
  }, [_createElementVNode("img", {
    src: _ctx.imageSrc,
    alt: _ctx.imageAlt,
    class: "w-100 h-100 rounded",
    id: "img"
  }, null, 8, _hoisted_1), _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1)], 2);
}