import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-2b7ab1ea"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = ["id"];
var _hoisted_2 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    id: _ctx.id,
    class: "logo-container"
  }, [_createElementVNode("img", {
    class: "logo",
    src: _ctx.logoSrc
  }, null, 8, _hoisted_2)], 8, _hoisted_1);
}