import { defineComponent, onMounted, onBeforeUnmount } from "vue";
import { setModal } from "@/core/helpers/config";
import Close from "./parts/Close.vue";
export default defineComponent({
  components: {
    Close: Close
  },
  props: {
    title: String,
    maxWidth: {
      type: String,
      default: "550px"
    },
    id: {
      type: String
    },
    closeButton: {
      type: Boolean,
      required: true
    },
    returnFunction: {
      type: Function,
      required: false
    },
    displayArrow: {
      type: Boolean,
      default: false
    },
    isMarginAuto: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup() {
    onMounted(function () {
      var body = document.body;
      body.style.top = "-".concat(window.scrollY, "px");
      body.style.position = "fixed";
      body.style.width = "100%";
    });
    onBeforeUnmount(function () {
      var body = document.body;
      var scrollY = body.style.top;
      body.style.position = "";
      body.style.top = "";
      body.style.width = "";
      window.scrollTo(0, parseInt(scrollY || "0") * -1);
    });
    return {
      setModal: setModal
    };
  }
});