import { defineComponent, computed } from "vue";
import ModalLayout from "@/components/modals/ModalLayout.vue";
import InnerImageZoom from "vue-inner-image-zoom";
import "vue-inner-image-zoom/lib/vue-inner-image-zoom.css";
import { useStore } from "vuex";
import { buildTreatedImageUrl } from "@/core/helpers/config";
export default defineComponent({
  name: "zoom-modal",
  components: {
    ModalLayout: ModalLayout,
    "inner-image-zoom": InnerImageZoom
  },

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  setup: function setup() {
    var store = useStore();
    var colorValue = computed(function () {
      return store.getters.getwallColor;
    });
    var floorPattern = computed(function () {
      return store.getters.getFloorPattern;
    });
    var imgName = computed(function () {
      return store.getters.getImgName;
    });
    var imgres = computed(function () {
      var u = buildTreatedImageUrl(imgName.value, {
        color: colorValue.value,
        floor: floorPattern.value
      });
      return u;
    });
    return {
      imgres: imgres
    };
  }
});