import { defineComponent } from "vue";
export default defineComponent({
  props: {
    closeButton: {
      type: Boolean,
      required: true
    }
  },
  components: {},
  setup: function setup() {
    return {};
  }
});